
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Muli:300,700);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

@import "quill-editor";

.fonterror {
    font-size: 140px;
}

ol li, ul li {
    padding-left: 1.1em;
}

li.nomargin {
    padding-left: 0em;
}

/* GENERALES */

h1, h2, h3{
    font-size: 30px;
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    color: #156032;
}

h1, h3.slideshowtxt {
    color: white;
}
h2.quienessomos{
    color: white;
}

p.gray{
    color: gray;
}

p.white{
    color: white;
}

.tamanioNoticiasFooter{
  font-size: 13px;
}

.marginfiel{
    margin-top: 4em;
}


body, p, li {
    font-size: 18px;
    color: black;
    font-family: 'Muli', sans-serif;
}

body{
    background-color: #FFF;
}

.padding0{
    padding: 0;
}

.bg-black{
    background-color: #211E1B;
}

.bgwhite{
    background-color: #FFF;
}

.bggray{
    background-color: #F5F8FA;
}

.white-letter{
    color: white;
}

.quienessomosmargin {
    margin: 58px 51px 11px;
}

/*------------------------------------------- CLASES DEL MENU COLLAPSABLE -------------------------------------------
header {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    background-color: rgba(255,255,255,.50);
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    -ms-transition: height 0.3s;
    -o-transition: height 0.3s;
    transition: height 0.3s;
}
header img#logo {
    display: inline-block;
    height: auto;
    line-height: 150px;
    float: right;
    margin-top: 11px;
    //font-family: "Oswald", sans-serif;
    //font-size: 60px;
    //color: white;
    //font-weight: 400;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;

}
header img#logo2 {
    display: inline-block;
    height: auto;
    line-height: 150px;
    float: left;
    margin-top: 11px;
    //font-family: "Oswald", sans-serif;
    //font-size: 60px;
    //color: white;
    //font-weight: 400;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
header nav {
    display: inline-block;
    float: left;
}
header nav a {
    line-height: 150px;
    margin-left: 20px;
    color: #FFF;
    font-weight: 700;
    font-size: 12px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}
header nav a:hover {
    color: #156032;
    text-decoration: none;
    border-bottom: 3px solid #156032;
}

a:hover, a:focus {
    color: #388AF3;
    text-decoration: none;
}
header.smaller {
    height: 95px;
    background-color: rgba(30,34,36,.93);
}
header.smaller img#logo {
    width: auto;
    height: 75px;
    line-height: 75px;
    //font-size: 30px;
    //transition: all 0.3s ease-in-out;

}

header.smaller img#logo2 {
    width: auto;
    height: 75px;
    line-height: 75px;
    //font-size: 30px;
    //transition: all 0.3s ease-in-out;

}
header.smaller nav a {
    line-height: 95px;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
}

@media all and (max-width: 768px) {
    header h1#logo {
        display: block;
        float: none;
        margin: 0 auto;
        height: 100px;
        line-height: 100px;
        text-align: center;
    }
    header nav {
        display: block;
        float: none;
        //height: 50px;
        text-align: center;
        margin: 0 auto;
    }
    header nav a {
        line-height: 50px;
        margin: 0 10px;
    }
    header.smaller {
        height: 0;
    }
    header.smaller h1#logo {
        height: 40px;
        line-height: 40px;
        font-size: 30px;
    }
    header.smaller nav {
        height: 35px;
    }
    header.smaller nav a {
        line-height: 35px;
    }
}
 
/*------------------------------------------- FIN CLASES DEL MENU COLLAPSABLE -------------------------------------------*/



/* ------------------------------------------------------------INICIO SLIDESHOW------------------------------------------------------------ */

/*
Image credits: unsplash.com
Fade content bs-carousel with hero headers
Code snippet by maridlcrmn (Follow me on Twitter @maridlcrmn) for Bootsnipp.com
*/

/********************************/
/*       Fade Bs-carousel       */
/********************************/
.fade-carousel {
    position: relative;
    height: 100vh;
}
.fade-carousel .carousel-inner .item {
    height: 100vh;
}
.fade-carousel .carousel-indicators > li {
    margin: 0 2px;
    background-color: #bebebe;
    border-color: #bebebe;
    opacity: .7;
}
.fade-carousel .carousel-indicators > li.active {
  width: 10px;
  height: 10px;
  opacity: 1;
}

/********************************/
/*          Hero Headers        */
/********************************/
.hero {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    color: #fff;
    text-align: center;

    text-shadow: 1px 1px 0 rgba(0,0,0,.75);
      -webkit-transform: translate3d(-50%,-50%,0);
         -moz-transform: translate3d(-50%,-50%,0);
          -ms-transform: translate3d(-50%,-50%,0);
           -o-transform: translate3d(-50%,-50%,0);
              transform: translate3d(-50%,-50%,0);
}
.hero h1 {
    font-size: 64px;    
    font-weight: bold;
    margin: 0;
    padding: 0;
}

.fade-carousel .carousel-inner .item .hero {
    opacity: 0;
    -webkit-transition: 2s all ease-in-out .1s;
       -moz-transition: 2s all ease-in-out .1s; 
        -ms-transition: 2s all ease-in-out .1s; 
         -o-transition: 2s all ease-in-out .1s; 
            transition: 2s all ease-in-out .1s; 
}
.fade-carousel .carousel-inner .item.active .hero {
    opacity: 1;
    -webkit-transition: 2s all ease-in-out .1s;
       -moz-transition: 2s all ease-in-out .1s; 
        -ms-transition: 2s all ease-in-out .1s; 
         -o-transition: 2s all ease-in-out .1s; 
            transition: 2s all ease-in-out .1s;    
}

/********************************/
/*            Overlay           */
/********************************/
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: #080d15;
    opacity: .7;
}

/********************************/
/*          Custom Buttons      */
/********************************/
.btn.btn-lg {padding: 10px 40px;}
.btn.btn-hero,
.btn.btn-hero:hover,
.btn.btn-hero:focus {
    color: #f5f5f5;
    background-color: #1abc9c;
    border-color: #1abc9c;
    outline: none;
    margin: 20px auto;
    text-decoration: none;
}

/********************************/
/*       Slides backgrounds     */
/********************************/
.fade-carousel .slides .slide-1, 
.fade-carousel .slides .slide-2,
.fade-carousel .slides .slide-3 {
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.fade-carousel .slides .slide-1 {
  background-image: url('../img/slide1.jpg'); 
}
.fade-carousel .slides .slide-2 {
  background-image: url('../img/slide2.jpg');
}
.fade-carousel .slides .slide-3 {
  background-image: url('../img/slide3.jpg');
}

/********************************/
/*          Media Queries       */
/********************************/
@media screen and (min-width: 980px){
    .hero { width: 980px; }    
}
@media screen and (max-width: 640px){
    .hero h1 { font-size: 2em; } 

}




@media screen and (min-width: 320px){

    .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        max-height: 502px;
    }
}

@media screen and (min-width: 568px){

    .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        max-height: 254px;
    }

}

@media screen and (min-width: 732px){

    .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        max-height: 300px;
    }

}

@media screen and (min-width: 768px){

    .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        max-height: 566px;
    }

}

@media screen and (min-width: 769px){

    .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
        max-height: 562px;
    }
}



    

.sha{
      box-shadow: 1px 1px 49px rgba(0,0,0,0.6);
}


/* ------------------------------------------------------------FIN SLIDESHOW------------------------------------------------------------ */

/* ------------------------------------------------------------INICIO BOTON MENU------------------------------------------------------------ */

.botonReserva{

    
    margin-top: 0.5em;
    display: block;
    
    padding: 25px 0 0 0;
    margin: 3.8em auto;
    
    background: #D38F4E;
    //background: -moz-linear-gradient(top, #C69E59 0%, #A87F41 100%);
    //background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#C69E59), color-stop(100%,#A87F41));
 
    //box-shadow: inset 0px 0px 6px #fff;
    //-webkit-box-shadow: inset 0px 0px 6px #fff;
    //border: 1px solid #62C2F9;
    border-radius: 10px;
 
    font-family: 'Quicksand', sans-serif;;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #FFF;

    padding: 8px;
    width: 119%;
    font-weight: 600;
    font-size: 14px;
   // color: #FFF;
    //background-color: #A87F41;
    border-radius: 0px;
    border: 1px solid #D38F4E;
    //box-shadow: 1px 1px 1px #000;
    //text-shadow: 0px 1px 2px #000;
}

.botonReserva:hover{

    
   
margin-top: 0.5em;
    display: block;
     
    padding: 25px 0 0 0;
    margin: 3.8em auto;
    
    background: #D38F4E;
    //background: -moz-linear-gradient(top, #C69E59 0%, #A87F41 100%);
    //background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#C69E59), color-stop(100%,#A87F41));
 
    //box-shadow: inset 0px 0px 6px #fff;
    //-webkit-box-shadow: inset 0px 0px 6px #fff;
    //border: 1px solid #62C2F9;
    border-radius: 10px;
 
    font-family: 'Quicksand', sans-serif;;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #D38F4E;

    padding: 8px;
    width: 119%;
    font-weight: 600;
    font-size: 14px;
   // color: #FFF;
    background-color: #fff;
    border-radius: 0px;
    border: 1px solid #D38F4E;
    //box-shadow: 1px 1px 1px 1px #D38F4E;
    //text-shadow: 0px 1px 2px #000;
}




/* ------------------------------------------------------------FIN BOTON MENU------------------------------------------------------------ */


/* ---------------------------------------------------------------- TITULO------------------------------------------------ */

/*.principal-color{
    color: #156032;
}*/

.blackletter{
    color: black;
}

.margin-container{
    margin-top: 2em;
    margin-bottom: 3em;
}

/* INICIO OCULTAR CAPTCHA */

.grecaptcha-badge {
  display: none;
}
/* FIN OCULTAR CAPTCHA */
/* ---------------------------------------------------------------- TITULO------------------------------------------------ */

/* INICIO LEER MAS BOTON */

.readmorebutton{
    margin-top: 0.5em;
    padding: 25px 0 0 0;
     margin: 2.6em auto;
    background: transparent;
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    color: #FFF;
    padding: 6px;
    width: 35%;
    font-weight: 600;
    font-size: 14px;
    border-radius: 0px;
    border: 1px solid #5DA46C;
}

.readmorebutton:hover{
    margin-top: 0.5em;
    padding: 25px 0 0 0;
     margin: 2.6em auto;
    background: rgba(255,255,255,0.2);
    border-radius: 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
    padding: 6px;
    width: 35%;
    font-weight: 600;
    font-size: 14px;
    border-radius: 0px;
    border: 1px solid #5DA46C;
}

/* FIN LEER MAS BOTON */

.notfoo{
    margin-top: 20px;
}


/* INICIO SOMBRA BLANCA A IMAGEN */


.image {
  opacity: 1;
  //display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;

}

.middle {
  
  opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);

}

.middleproductos{
    opacity: 1;
    position: absolute;
    top: 42%;
    left: -0.5vw;
    width: 100%;
}

.iluminacion:hover .image {
  opacity: 0.7;
}

.iluminacion:hover .middle {
    color: white;
    font-size: 21.98px;
    //padding: 16px 32px;


}

.text {
    //background-color: #156032;
    color: white;
    font-size: 35px;
    //padding: 16px 32px;

}

.acolor{
    text-decoration: none;
    color: #FFF;
    font-size: 30px;
}

.acolor:hover{
    text-decoration: none;
    color: #FFF;
    font-size: 31px;
}



/* FIN SOMBRA BLANCA IMAGEN */


/* INICIO PARRAFO */

p.noticiashome {
    margin-top: 1em;

}

.mgnquienessomosm{
    margin-top: -22px;
}

    

a.green {
    text-decoration: none;
    color: #156032;
}
/* FIN PARRAFO */

/* BOTON NOTICIAS */

/* INICIO LEER MAS BOTON */

.noticiasbutton{
    //margin-top: 0.5em;
    display: block;
    
   // padding: 25px 0 0 0;
    //margin: 3.8em auto;
    
    background: transparent;
    //background: -moz-linear-gradient(top, #C69E59 0%, #A87F41 100%);
    //background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#C69E59), color-stop(100%,#A87F41));
 
    //box-shadow: inset 0px 0px 6px #fff;
    //-webkit-box-shadow: inset 0px 0px 6px #fff;
    //border: 1px solid #62C2F9;
    border-radius: 10px;
 
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #156032;

    padding: 8px;

    font-weight: 600;
    font-size: 14px;
   // color: #FFF;
    //background-color: #A87F41;
    border-radius: 0px;
    border: 1px solid #156032;
    //box-shadow: 1px 1px 1px #000;
    //text-shadow: 0px 1px 2px #000;
}

.noticiasbutton:hover{
   // margin-top: 0.5em;
    display: block;
    
    //padding: 25px 0 0 0;
    //margin: 3.8em auto;
    
    background: rgba(0,0,0,0.2);
    //background: -moz-linear-gradient(top, #C69E59 0%, #A87F41 100%);
    //background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#C69E59), color-stop(100%,#A87F41));
 
    //box-shadow: inset 0px 0px 6px #fff;
    //-webkit-box-shadow: inset 0px 0px 6px #fff;
    //border: 1px solid #62C2F9;
    border-radius: 10px;
 
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #156032;

    padding: 8px;

    font-weight: 600;
    font-size: 14px;
   // color: #FFF;
    //background-color: #A87F41;
    border-radius: 0px;
    border: 1px solid #156032;
}

/* FIN LEER MAS BOTON */

.envoilment{
    padding: 11px 41px;
}

.envoilment-instalaciones {
   padding: 10px 73px 0px 41px;
}

/* FIN BOTON NOTICIAS */

.strong{
    font-weight: bold;
}


.bgform{
        background:  url('../img/fondo_formV2.jpg') no-repeat ;
        //margin-right:30px;
      }

.topfooter{
  margin-top: 25px;
}

.marginformbuttom{
  margin-bottom: 25px;

}

.whiteletra{
  color: white;
}


.bgcolor{
    background-color: #FFF;
}

.bordebottominput {
    border-bottom: 1px solid #166033;
}
.noneborder {
    border-top: 1px solid #166033;
    border-left: 1px solid #166033;
    border-right: 1px solid #166033;

}


.marginparallax{
    margin-top: 2em;
    margin-bottom: 2em;
}

.botonenviar {
    background-color: white; /* Green */
    border: 1px solid #166033;
    color: #166033;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
    //display: inline-block;
    font-size: 18px;
    //margin: 7px 34%;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
    width: 100%
    //border-radius: 12px;
}

.botonenviar:hover {
    background-color: rgb(22,96,51); /* Green */
    border: 1px solid #166033;
    color: #fff;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
    //display: inline-block;
    font-size: 18px;
    //margin: 7px 34%;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
    width: 100%
    //border-radius: 12px;
}

.botonleermas {
    background-color: transparent; /* Green */
    border: 1px solid #166033;
    color: #166033;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
    //display: inline-block;
    font-size: 18px;
    //margin: 7px 34%;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
    //border-radius: 12px;
}

.botonleermas:hover {
    background-color: rgb(22,96,51); /* Green */
    border: 1px solid #166033;
    color: #fff;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
    //display: inline-block;
    font-size: 18px;
    //margin: 7px 34%;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
    //border-radius: 12px;
}

.button:hover {
    background-color: #03672B; /* Green */
    border: none;
    color: white;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
   //display: inline-block;
    font-size: 18px;
    
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
}

.button {
    background-color: white; 
    color: #03672B; 
    border-radius: 12px;
    //border: 2px solid #03672B;
    border: none;
    padding: 5px 32px;
    text-align: center;
    text-decoration: none;
   //display: inline-block;
    font-size: 18px;

    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
}

.mleftform{
    margin-left: 20%;
}



.nonnborder{
    border: 0px;
}



.bgdarckfooter{
    background-color: #222222;
}


.centerform{
    justify-content: center;
    display: flex;
}

.hijo {
  padding: 10px;
  margin: 10px;
  //background-color: yellow;
}


@media screen and (min-width: 992px) {

        .navbar-default {
            padding: 15px 0;
            transition: padding 0.3s;
        }

            .navbar-default.navbar-shrink {
                padding: 10px 0;
            }
    }

    .navbar-default a {
        color: #156032;
        text-transform: uppercase;
        text-decoration: none;
        line-height: 42px;
        font-weight: 700;
        font-size: 14px;
    }

    .navbar-default a.brand > img {            
        max-width: 70px;
    }

        .navbar-default a.active {
            color: #2dbccb;
        }


    .content {
        position: absolute;
        width: 100%;
        height: 100%;
    }

        .content > section {
            width: 100%;
            height: 100%;
        }


header,nav, a, img, li{
  transition: all 0.5s;
  -moz-transition: all 0.5s; /* Firefox 4 */
  -webkit-transition: all 0.5s; /* Safari and Chrome */
  -o-transition: all 0.5s; /* Opera */
}


.navbar-toggle {
    position: relative;
    float: right;
    margin-right: 21px;
    padding: 0px 9px;
    margin-top: 26px;
    margin-bottom: 18px;
    background-color: transparent;
    background-image: none;
    border: 0px solid transparent;
    border-radius: 4px;
}

.navbar-default .navbar-nav > li > a {
    color: #166033;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
    color: #166033;
    background-color: transparent;
    border-bottom: 2px solid #166033;
}

.navbar-default {
    background-color: white;      //COLOR DEL MENU SIN SCROLL

}

.navbar-default.navbar-shrink {
    padding: 0px 0;
    background-color: rgba(255, 255, 255, 1); 
      //COLOR DEL MENU CON SCROLL
}
.navbar-nav > li > a {
    //padding-top: 31px;      //margintop de menu (li)
    padding-bottom: 12px;
}

.prelative{
  position:relative;
}

.pabsolute{
  position: absolute;
}

.textosobreimagen{

  bottom:50%;
  width:100%;
  text-align:center;
  color:rgba(243,243,243,.8);
  font-family:Verdana;
  font-size:14px;
  font-style: italic;
  background-color:rgba(0,0,0,.7);
  padding:.5em 0;
}

.padding0{
  padding: 0;
}

.bgarquitectura{
   background: url('../img/arq-jvV2.jpg') no-repeat fixed center;
   -webkit-background-size: cover;
   -moz-background-size: cover;
   -o-background-size: cover;
       background-size: cover;
    height: 100%;
    width: 100%;
    text-align: center;
    margin-top: 4em;
 
}
.tituloarq {
      padding: 0px;
    display: inline-block;
    margin-top: 5%;
    font-size: 43px;
    color: white;
    margin-bottom: 3%;
}

.tituloTexto{
  color:green;
  font-size: 20px;
  margin-top: 2em;
}

.texto{
  font-size: 18px;
  color: black;
  margin-top: 1em;
  margin-bottom: 1em;
}

@media (max-width: 320px){
 

  .titulo{
    font-size: 30px;
  }
}


@media (max-width: 425px){
 
    .envoilment {
        padding: 11px 0px;
    }

    .envoilment-instalaciones {
        padding: 10px 0px 0px 0px;
    }
}

.principalCalidad{
    background-color: #6EAC7B;
    color: white;
    font-weight: bold;

}

.secundarioCalidad{
    background-color: #93C19C;
    font-weight: bold;
     

}

p.secundario{
    color: white;
}

.DimensionesTolerancia{
    background-color: #B8D6BE;
    font-weight: bold;
    outline: 2px solid #fff;


}

.margintable{
   outline: 2px solid #fff;


}



.margincelda{
    margin: 1.5em 0px;
    font-weight: bold;
}

.paddingsecudanrios{
    padding: 1em;
}

.primero{
    background-image:  url('../img/1icon.png');
}
.segundo{
    list-style-image: url('../img/segundo.png');
}
.tercero{
    list-style-image: url('../img/tercero.png');
}
.cuarto{
    list-style-image: url('../img/cuarto.png');
}
.quinto{
    list-style-image: url('../img/quinto.png');
}
.sexto{
    list-style-image: url('../img/sexto.png');
}
.septimo{
    list-style-image: url('../img/septimo.png');
}

.liststylenone{
    list-style:none;
}
ol.liststylenone {
    margin-bottom: 11px;
}


.paddingtext-noticias{
     padding: 10px 10px;
}

.text-decoration-none{
    color: #fff;
    text-decoration: none;
}


.topsmproducto{
    margin-top: 9vw;
}


.imgprocesos{
    min-width: 100%;
    height: 319px;
}



p, li{
    color: black;
    font-weight: 300;
    //padding: 0 0 21px 0;
    line-height: 1.5em;
}


ul.nonelist{
       list-style:none;
       }
span.icon-check{
    color: #6EAC7B;
}

.mgnntn{
    margin-bottom: 1em;
}

.fright{
    float: right;
}

.marginnoticias{
    margin-bottom: -2em;
}

.botoncatalogomenu{

    display: block;    
    background: #156032;
    border-radius: 10px; 
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #FFF;
    padding: 0px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 0px;
    border: 1px solid #156032;
    margin-top: -0.5em;
    width: 100%
}

.botoncatalogomenu:hover{

    display: block;    
    background: #fff;
    border-radius: 10px; 
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: #156032;
    padding: 0px;
    font-weight: 600;
    font-size: 12px;
    border-radius: 0px;
    border: 1px solid #156032;
    margin-top: -0.5em;
    width: 100%
}




  li.botoncat > a.descargacatalogo:hover,  li.botoncat > a.descargacatalogo:focus {

    border-bottom: 0px;
}


.marginfecha{
    margin-top: -12px;
}

.marginbotonborrar {
    margin-right: 2%;
}

.mgnbotmnot{
    margin-bottom: 4.5em;
}

.mgtopfotonot{
        margin-top: 5.2em;
}


.botonproductos{

    display: block;
    background: transparent;
    border-radius: 10px;
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    color: white;
    padding: 3px;
    font-weight: 600;
    font-size: 18px;
    border-radius: 0px;
    border: 1px solid #FFF;
    margin-top: 1em;
    width: 156px;
}

.botonproductos:hover{

    display: block;
    background: rgba(0,0,0,1);
    border-radius: 10px;
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    color: #FFF;
    padding: 3px;
    font-weight: 600;
    font-size: 18px;
    border-radius: 0px;
    border: 1px solid #000;
    margin-top: 1em;
    width: 156px;
}

.buttonxs{
    //display: block;
    background: transparent;
    //border-radius: 10px;
    font-family: 'Muli', sans-serif;
    //font-weight: 700;
    text-align: center;
    text-decoration: none;
    color: white;
    padding: 3px;
    font-weight: 600;
    font-size: 18px;
    //border-radius: 0px;
    border: 1px solid #FFF;
    //margin-top: 1em;
    //width: 14vw;

}


.calidadpaersamargin{

    margin-top: 1.55em;
    margin-bottom: 1.58em;

}

.mamparasprod{
    margin-bottom: 1.55em;
}


.mediovertical{
        -ms-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}



@media (max-width: 1257px) {
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }

    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none;
        padding-left: 1.1em !important;
        >a, .dropdown-toggle {
            padding-left: 0 !important;
        }
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }

    
}

.navbar-default .navbar-toggle {
    border-color: #156032;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #156032;
}

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
}

.margintoggle{
    margin-right: 1.2em;
}

.marginlogo{
    margin-left: 1em;
    padding: 10px 0;
}


@media (min-width: 768px){
    .navbar-nav {
    float: left;
    margin: 11px;
            }

}

/* FORMULARIO */

.form-control {
  display: block;
  width: 100%;
  height: 36px;
  padding: 0px 0px;
  font-size: 14px;
  line-height: 1.6;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccd0d2;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.error-formu {
    color: #ff6666;
    font-weight: 800;
    margin: 0 0 0 10px;
    font-size: 18px;
    /* padding: 3px 9px; */
    // font-family: 'Poppins', sans-serif;
    // font-weight: 600;
}
.mensaje-ok {
    color: green;
    font-weight: 800;
    margin: 0 0 0 10px;
    padding: 3px 10px;
    font-size: 18px;
    // font-family: 'Poppins', sans-serif;
    // font-weight: 600;
}

input.error-formu textarea.error-formu {
    border-color: #ff6666;
}

#spam{display:none;}

.bginput {

  background-color : transparent; 
  font-size: 18px;

}






/* FIN FORMULARIO */




.dropdown-menu > li > a {
    display: block;
    padding: 6px 20px;
    clear: both;
    font-weight: bold;
    line-height: 2.2;
    color: #156032;
    white-space: nowrap;
    padding-left: 0em;
    padding-right: 2em;
}

.dropdown-menu2 > li > a {
    display: block;
    padding: 6px 20px;
    clear: both;
    font-weight: bold;
    line-height: 2.2;
    color: #156032;
    white-space: nowrap;
    padding-left: 0em;
    padding-right: 2em;
}



@media (min-width: 768px){
    .navbar-right .dropdown-menu {
    right: auto;
    left: auto;
    }
}

.div-images-zoom {
  //position: relative;
  
  //border: 1px solid #333;
  //margin: 2%;
  overflow: hidden;
  padding: 0 0 ;
  //width: 540px;
}
.div-images-zoom img {
  max-width: 101%;
  
  -moz-transition: linear 0.3s;
  -webkit-transition: linear 0.3s;
  transition: linear 0.3s;
  padding: 0 0;

}
.div-images-zoom:hover img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  padding: 0 0px;

}


.margin1emleft{
    margin-left:1em;
}

.marginleft2em{
    margin-left:2em;
}

.margin2emRight{
    margin-right:2em;
}

.mgntopbtn{
    margin-top: 4em;
}


li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em;
    display: none;
}


#gradproductos {
    height: 100%;
    background: white; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(white, #CCF4DB ); /* For Safari 5.1 to 6.0 */
    background: linear-gradient(white, #CCF4DB ); /* Standard syntax (must be last) */
    background: -o-linear-gradient(white, #CCF4DB ); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(white, #CCF4DB ); /* For Firefox 3.6 to 15 */
}


.bgdarkgray{
    background-color: #181818;
}

.marginfootdark{
    padding-top: 0.5em;
    padding-bottom: 0.2em;
}

.mgntopdarkfooter{
    margin-top: 2em;
}

.btmpadd{
   padding-bottom: 1em;    
}



li.chevron:before {
  content: "\f054"; /* FontAwesome Unicode */
  color: #166033;
  font-family: FontAwesome;
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
  margin-right: 0em; 
  text-align: left; 
  white-space: nowrap;
  font-size: 0.7em;

}

.paddingconstruction{
    padding: 2em;
}

.medio{
    opacity: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
}

.lhe{
    line-height: 1em;   
}

.margintxtfootfor{
    margin-top: 30px;
}


li.chevron{
    padding-bottom: 0.8em;
}


.dropdownmenupadding{
    padding-top: 0.2em;
    padding-left: 0.5em;
}



ol, ul {
    padding-left: 0em; 
}

.oculto {
    visibility: hidden;
}

.paddingtopcontact{
        padding-top: 29px;
}





.paddingCatalogomenu {
    padding-right: 0.9em;
    margin-top: 1.1em;
}


.topidiomas {
    /* padding-right: 0.9em; */
    margin-top: 0.5em;
    .dropdown-idiomas {
        min-width: 180px;
    }
}


.dropdown-idiomas {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px -74px 0;
    list-style: none;
    font-size: 14px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
}


.idiomasmobile{
    margin-top: 13px;
}

.min-heightblackbg{
    min-height: 423px;
}

h5, .h5 {
    font-size: 18px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 230px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
}



@media (min-width: 768px) and (max-width:1024px){
    .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    left: 118px;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
}
}

@media (min-width: 1024px) and (max-width:1366px){
    .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 1000;
    display: none;
    float: left;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
}
}
